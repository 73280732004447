// error reporting is in it's own pack
// because it needs to be loaded first in the layout
import { handleError } from "lib/handleErrors";

/* trigger in console with:
setTimeout(function() { notThere(); }, 0);
*/
window.onerror = handleError;

/* trigger in console with:
function rejectPromise() {
  return Promise.reject(new Error('rejected promise'));
}

async function throwAsync() {
  throw new Error('async exception');
}

function fail() {
  throw new Error('exception');
}

rejectPromise().then(() => console.log('success'));
throwAsync();
setTimeout(function() { fail();}, 0);
*/
window.onunhandledrejection = handleError;