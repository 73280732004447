import { UAParser } from "ua-parser-js";

// we only care about errors from supported browsers
export function unsupported(brand, _version) {
  var version = parseInt(_version);

  if(brand == 'Chrome' && version > 110) {
    return false;
  }

  if(brand == 'Safari' && version > 15) {
    return false;
  }

  if(brand == 'Mobile Safari' && version > 15) {
    return false;
  }

  if(brand == 'Edge' && version > 110) {
    return false;
  }

  if(brand == 'Firefox' && version > 110) {
    return false;
  }

  return true;
}

let errorsReported = {};

export function handleError(eventOrMessage, url, lineNumber, colNumber, error) {
  // don't want to double report same error
  if(typeof errorsReported === 'undefined') {
    errorsReported = {};
  }

  let errorKey = error;
  if(eventOrMessage?.reason) {
    errorKey = `${eventOrMessage.reason.name}: ${eventOrMessage.reason.message}`
  }

  if(errorsReported[errorKey] || !eventOrMessage) {
    return;
  }

  var agent = new UAParser(window.navigator.userAgent);
  var browser = agent.getBrowser();

  // if unsupported browser then return
  if(unsupported(browser.name, browser.major)) {
    return;
  }

  // some browsers provide an object instead of destructured keys
  if (typeof eventOrMessage !== 'string') {
    error = eventOrMessage.error || eventOrMessage.reason;
    url = eventOrMessage.filename || eventOrMessage.fileName;
    lineNumber = eventOrMessage.lineno || eventOrMessage.lineNumber;
    colNumber = eventOrMessage.colno || eventOrMessage.columnNumber;
    eventOrMessage = eventOrMessage.message || eventOrMessage.name || error.message || error.name;
  }

  if (error && error.stack) {
    eventOrMessage = [eventOrMessage, '; Stack: ', error.stack, '.'].join('');
  }

  var jsFile = (/[^/]+\.js/i.exec(url || '') || [])[0] || 'inlineScriptOrDynamicEvalCode';
  var stack = [eventOrMessage, ' Occurred in ', jsFile, ':', lineNumber || '?', ':', colNumber || '?'].join('');
  var visitorId = document.cookie.split("; ").find((row) => row.startsWith("th_visitor_id="))?.split("=")[1];

  // ignore some errors
  var ignoreList = ['gtm.js?id=', 'otSDKStub'];
  const ignoreError = ignoreList.some(str => stack.includes(str));

  if(ignoreError) {
    return;
  }

  var body = {
    href: window.location.href,
    pathname: window.location.pathname, // could be extracted from href
    search: window.location.search, // could be extracted from href
    message: eventOrMessage, // could be extracted from fullText
    url: url || window.location.href, // could be extracted from fullText
    lineNumber: lineNumber, // could be extracted from fullText
    colNumber: colNumber, // could be extracted from fullText
    stackTrace: stack,
    jsFile: jsFile,
    userAgent: window.navigator.userAgent,
    os: agent.getOS().name, // could be extracted from userAgent
    browser: browser.name, // could be extracted from userAgent
    browserVersion: browser.version, // could be extracted from userAgent
    thVisitorId: visitorId
  }

  var formData = new FormData();
  formData.append("authenticity_token", document.querySelector('meta[name="csrf-token"]').content);

  for(var key in body) {
    formData.append(key, body[key]);
  }

  window.navigator.sendBeacon('/api/v1/client_errors', formData);
  errorsReported[errorKey] = 1;
}